import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { PhotoSwipeGallery } from 'react-photoswipe'
import Button from '../components/button'

const PhotoGallerySection = styled.section`
  img {
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    border-radius: 2px;
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 12px -2px;
    }
  }
  .pswp-thumbnails {
    display: flex;
    flex-wrap: wrap;
  }
  .pswp-thumbnail {
    flex: 0 0 25%;
    padding: 2%;
    @media (max-width: 992px) {
      flex: 0 0 33%;
    }
    @media (max-width: 767px) {
      flex: 0 0 50%;
    }
  }
`

const HeadingArea = styled.div`
  background: #ececec;
  text-align: center;
  padding: 50px 10px;
  padding-bottom: 100px;
  h1 {
    margin-bottom: 0.25em;
    font-size: calc(1vw + 1.35rem);
  }
  p {
    max-width: 767px;

    color: #666;
    margin: 0 auto;
    margin-bottom: 0.5em;
  }
`

const ShowroomPage = props => (
  <StaticQuery
    query={graphql`
      query ShowroomPhotos {
        site {
          siteMetadata {
            companyInfo {
              addressLine1
              addressLine2
              city
              state
              zip
            }
          }
        }
        allContentfulPhotoGallery(
          filter: { id: { eq: "969d0eff-b069-555a-a115-2c2d3f39947e" } }
        ) {
          edges {
            node {
              id
              title
              description {
                description
              }
              photos {
                id
                title
                gatsbyImageData(width: 1200)
                fluid(maxWidth: 1200) {
                  ...GatsbyContentfulFluid
                }
                fixed(width: 500, height: 500) {
                  ...GatsbyContentfulFixed
                }
              }
            }
          }
        }
      }
    `}
    render={() => null}
    // render={data => {
    //   let items = [] // initialize array
    //   {
    //     data.allContentfulPhotoGallery.edges[0].node.photos.map((item, i) => {
    //       let tempItem = {
    //         id: item.id,
    //         title: 'US Quality Construction Showroom',
    //         src: item.fluid.src,
    //         thumbnail: item.fixed.src,
    //         w: 1200,
    //         h: 900,
    //       }

    //       items.push(tempItem)
    //     })
    //   }

    //   let options = {}

    //   const getThumbnailContent = item => {
    //     return <img src={item.thumbnail} alt={item.title} />
    //   }

    //   return (
    //     <>
    //       <Layout pageProps={props}>
    //         <SEO title="Kitchen Cabinets Showroom Sacramento"
    //          description={
    //           'Visit our Sacramento Kitchen and Bathroom Showroom for your next home remodeling project ideas.'
    //         } />
    //         <HeadingArea>
    //             <div className="container">
    //                 <h1>Sacramento {data.allContentfulPhotoGallery.edges[0].node.title}</h1>
    //                 <p>{data.allContentfulPhotoGallery.edges[0].node.description.description}</p>
    //                 <a href={"http://maps.google.com/?q="+data.site.siteMetadata.companyInfo.addressLine1 + " " +data.site.siteMetadata.companyInfo.city +","+ data.site.siteMetadata.companyInfo.state+ " " +data.site.siteMetadata.companyInfo.zip} target="_blank"><Button color="white">{data.site.siteMetadata.companyInfo.addressLine1} {data.site.siteMetadata.companyInfo.city}, {data.site.siteMetadata.companyInfo.state} {data.site.siteMetadata.companyInfo.zip}</Button></a>
    //             </div>
    //             </HeadingArea>
    //         <div className="container"  style={{'marginTop':'-70px'}}>
    //         <PhotoGallerySection>
    //           <PhotoSwipeGallery
    //             items={items}
    //             options={options}
    //             thumbnailContent={getThumbnailContent}
    //           />
    //         </PhotoGallerySection>
    //         </div>
    //       </Layout>
    //     </>
    //   )
    // }}
  />
)

export default ShowroomPage
